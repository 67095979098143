import { useNavigate, useLocation } from 'react-router-dom';

const useRedirectToLogin = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const redirectToLogin = () => {
        const previousLocation = location.pathname + location.search;
        console.log(previousLocation);
        navigate('/login', { state: { from: previousLocation } });
    };

    return redirectToLogin;
};

export default useRedirectToLogin;
