import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const TMDB_API_KEY = process.env.REACT_APP_TMDB_API_KEY;
const TMDB_AUTH_KEY = process.env.REACT_APP_TMDB_AUTH_KEY;

export const tmdbApi = createApi({
  reducerPath: 'tmdbApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.themoviedb.org/3/',
    prepareHeaders: (headers, { endpoint }) => {
      headers.set('accept', 'application/json');
      headers.set('Authorization', `${TMDB_AUTH_KEY}`);
      return headers;
    },
  }),
  endpoints: (builder) => ({
    searchMoviesAndShows: builder.query({
      // Define the query with 'lazy' to make it a lazy query
      query: (query) => `search/multi?api_key=${TMDB_API_KEY}&query=${query}`,
      // Define it as a lazy endpoint
      providesTags: ['MoviesAndShows'],
    }),
    getRecommendations: builder.query({
      // Define the query with 'lazy' to make it a lazy query
      query: ({ mid, tid }) => {
        const type = mid ? 'movie' : 'tv';
        const id = mid || tid;
        return `${type}/${id}/recommendations?api_key=${TMDB_API_KEY}`;
      },
      // Define it as a lazy endpoint
      providesTags: ['recommendations'],
    }),

    searchPerson: builder.query({
      query: (query) => `search/person?api_key=${TMDB_API_KEY}&query=${query}`,
      providesTags: ['person'],
    }),
    getPopularMovies: builder.query({
      query: () => `/movie/popular?api_key=${TMDB_API_KEY}&language=en-US&page=1`,
      providesTags: ['popularMovies'],
    }),
    searchPersonId: builder.query({
      query: (pid) => `person/${pid}/combined_credits?api_key=${TMDB_API_KEY}&language=en-US`,
      providesTags: ['personId'],
    }),
  }),
});

// Export the lazy hook
export const { useLazySearchMoviesAndShowsQuery,useSearchPersonQuery,useLazySearchPersonIdQuery,useGetRecommendationsQuery ,useGetPopularMoviesQuery} = tmdbApi;
