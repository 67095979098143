import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { useSearchPersonQuery, useLazySearchPersonIdQuery } from '../actions/tmdbApiSlice';
import CircularProgress from '@mui/material/CircularProgress';

// Placeholder image URL
const placeholderImageUrl = 'https://via.placeholder.com/102x169.png?text=Poster+Not+Available';

const PeopleScreen = () => {
  const [searchParams] = useSearchParams();
  const searchQuery = searchParams.get('q');
  const isDirector = searchParams.has('dir')



  const { data, error, isFetching, isSuccess } = useSearchPersonQuery(searchQuery);
  const [triggerSearch, { data: pidData }] = useLazySearchPersonIdQuery();

  useEffect(() => {
    if (isSuccess && data?.results && data.results.length > 0) {
      const personId = data.results[0].id;
      // Fetch more details using lazy query
      triggerSearch(personId);
    }
  }, [isSuccess, data, triggerSearch]);

  const navigate = useNavigate();

  const handlePersonItemNavigate = (item) => {
    let year = '';

    if (item.media_type === 'movie' && item.release_date) {
      year = item.release_date.split('-')[0];
    } else if (item.media_type === 'tv' && item.first_air_date) {
      year = item.first_air_date.split('-')[0];
    }


    const idParam = item.media_type === 'tv' ? 'tid' : 'mid';

    const titleWithYear = year ? `${item.title || item.name} ${year}` : item.title || item.name;
    navigate(`/details/?q=${titleWithYear}&${idParam}=${item.id}`);

  };

  if (isFetching) {
    return (
      <Container className="d-flex justify-content-center align-items-center" style={{ minHeight: '100vh' }}>
        <CircularProgress color="inherit" />
      </Container>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // Ensure person is the first item in the list
  const personList = data?.results;
  const person = personList && personList.length > 0 ? personList[0] : null;

  // Sorting function to sort by release_date or first_air_date
  const sortedCast = pidData?.cast?.slice().sort((a, b) => {
    const dateA = a.release_date || a.first_air_date;
    const dateB = b.release_date || b.first_air_date;
    if (!dateA) return 1; // Handle null dates
    if (!dateB) return -1; // Handle null dates
    return new Date(dateB) - new Date(dateA); // Sort descending (latest first)
  });


  const sortedCrew = pidData?.crew?.slice().sort((a, b) => {
    // Check if either item has the job "Director"
    const isDirectorA = a.job === "Director";
    const isDirectorB = b.job === "Director";

    // If one is a director and the other is not, prioritize the director
    if (isDirectorA && !isDirectorB) return -1;
    if (!isDirectorA && isDirectorB) return 1;

    // If both are directors or both are not, sort by date
    const dateA = a.release_date || a.first_air_date;
    const dateB = b.release_date || b.first_air_date;
    if (!dateA) return 1; // Handle null dates
    if (!dateB) return -1; // Handle null dates
    return new Date(dateB) - new Date(dateA); // Sort descending (latest first)
  });

  const itemsToRender = isDirector ? sortedCrew : sortedCast;
  return (
    <Container className="py-4">
      {person && (
        <div className="text-center mb-4">
          {person.profile_path && (
            <img
              src={`https://image.tmdb.org/t/p/w300${person.profile_path}`}
              alt={`${person.name} poster`}
              className="rounded-circle"
              style={{ width: '150px', height: '150px', objectFit: 'cover' }}
            />
          )}
          <h4>{person.name}</h4>
        </div>
      )}

      <Row className="mt-4">
        <Col>
          <h5 className="mb-3">Known For</h5>
          <hr />
          <Row xs={3} md={4} lg={5} className="g-4">
            {itemsToRender && itemsToRender.map(item => (
              <Col key={item.id}>
                <div
                  className="d-flex flex-column align-items-center"
                  onClick={() => handlePersonItemNavigate(item)}
                  style={{ cursor: 'pointer' }}
                >
                  <img
                    src={item.poster_path ? `https://image.tmdb.org/t/p/w185${item.poster_path}` : placeholderImageUrl}
                    alt={`${item.title || item.name} poster`}
                    style={{ width: '102px', height: '169px', objectFit: 'cover' }}
                    className="mb-2"
                  />
                  <div className="text-center">
                    <p className="fw-bold mb-1">{item.title || item.name}</p>
                    <p className="fw-bold mb-1">As {item.job || item.character}</p>
                    <p className="fw-bold mb-1">Rating: {(Math.floor(item.vote_average * 10) / 10).toFixed(1)}</p>
                    <p className="text-muted">{item.release_date || item.first_air_date}</p>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PeopleScreen;
