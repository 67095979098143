import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api }  from './actions/apiSlice';
import { tmdbApi }  from './actions/tmdbApiSlice';
import { tvmazeApi }  from './actions/tvmazeSlice';
import { youtubeApi }  from './actions/searchTrailer';

const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  [tmdbApi.reducerPath]: tmdbApi.reducer,
  [tvmazeApi.reducerPath]: tvmazeApi.reducer,
  [youtubeApi.reducerPath]: youtubeApi.reducer,
});

const middleware = getDefaultMiddleware =>
  getDefaultMiddleware().concat(api.middleware, tmdbApi.middleware,tvmazeApi.middleware,youtubeApi.middleware);

export const store = configureStore({
  reducer: rootReducer,
  middleware,
});

// Optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);
