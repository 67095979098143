import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const api = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('aircheck_access');
      if (
        token &&
        ![
          'login',
          'register',
          'searchShow',


        ].includes(endpoint)
      ) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (build) => ({
    getWatchList: build.query({
      query: () => '/api/v1/movies/',
      providesTags: ['watchlist', 'searchTrailers'],
    }),
    addList: build.mutation({
      query: (data) => ({
        url: '/api/v1/movies/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['watchlist']

    }),
    deleteMovie: build.mutation({
      query: (deletedMovieId) => ({
        url: `/api/v1/movies/${deletedMovieId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['watchlist'],
    }),
    searchShow: build.query({
      query: (query) => `/api/v1/search-movie/?q=${query}`,
    }),
    funFacts: build.query({
      query: ({ name, year, kind }) => `/api/v1/fun-facts/?name=${name}&year=${year}&kind=${kind}`,
    }),
    reminder: build.query({
      query: () => '/api/v1/tvseries/',
      providesTags: ['reminders'],
    }),
    addReminder: build.mutation({
      query: (data) => ({
        url: '/api/v1/preference/',
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['reminders']

    }),
    deleteReminder: build.mutation({
      query: (id) => ({
        url: `/api/v1/tvseries/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['reminders']
    }),

    episodeAvailability: build.mutation({
      query: (data) => ({
        url: '/api/v1/aircheck/',
        method: 'POST',
        body: data
      }),

    }),

    profile: build.query({
      query: () => '/api/auth/user/',
      providesTags: ['profile'],
    }),

  }),
});

export const { useGetWatchListQuery, useDeleteMovieMutation, useSearchShowQuery, useReminderQuery, useDeleteReminderMutation, useAddReminderMutation, useEpisodeAvailabilityMutation, useAddListMutation, useFunFactsQuery ,useProfileQuery} = api;
