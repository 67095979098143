import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap'; // Import necessary components

import DeleteIcon from '@mui/icons-material/Delete';
const ConfirmModal = ({ id, onDelete }) => {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const handleConfirm = () => {
    onDelete(id); // Call the delete function when confirmed
    handleClose(); // Close the modal
  };

  return (
    <>
      <Button variant="danger" onClick={handleShow}>
        <DeleteIcon/>
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmModal;
