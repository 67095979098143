import React, { useEffect, useState } from 'react';
import { HashRouter as Router, Routes, Route, useLocation, } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';

import LoginScreen from './screens/LoginScreen';
import HomeScreen from './screens/HomeScreen';
import ShowDetailsScreen from './screens/ShowDetailsScreen';
import PeopleScreen from './screens/PeopleScreen';
import ReminderScreen from './screens/ReminderScreen';
import WatchlistScreen from './screens/WatchlistScreen';
import PrivacyPolicyScreen from './screens/PrivacyPolicyScreen';
import ProfileScreen from './screens/ProfileScreen';
import Footer from './components/Footer';
import Header from './components/Header';

const environment = process.env.REACT_APP_ENV;

if (environment === 'production') {
  ReactGA.initialize('G-L4RWP16VTP'); // Replace with your actual ID
}

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    if (environment === 'production') {
      const page = location.pathname + location.search;
      ReactGA.set({ page });
      ReactGA.send('pageview');
    }
  }, [location]);

  return null;
};

const App = () => {
  const [isWebView, setIsWebView] = useState(false);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const isWebViewParam = query.get('wv');
    console.log("isWebView param:", isWebViewParam); // Logs the value of isWebView
    setIsWebView(isWebViewParam === 'true/');
  }, []);

  return (
    <div>
      <Router>
         <Header />
        <RouteTracker />
        <Routes>
          <Route path="/" exact element={<HomeScreen />} />
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/details/?" element={<ShowDetailsScreen />} />
          <Route path="/person/?" element={<PeopleScreen />} />
          <Route path="/reminder" element={<ReminderScreen />} />
          <Route path="/watchlist" element={<WatchlistScreen />} />
          <Route path="/policy" element={<PrivacyPolicyScreen />} />
          <Route path="/profile" element={<ProfileScreen />} />
        </Routes>
        {!isWebView && <Footer />}
        <ToastContainer />
      </Router>
    </div>
  );
};

export default App;