import React, { useEffect, useRef } from 'react';
import { useFunFactsQuery } from '../actions/apiSlice';
import { skipToken } from '@reduxjs/toolkit/query/react';
import { helix } from 'ldrs';
import {  Container,  } from 'react-bootstrap';

import './FunFacts.css';
helix.register('l-helix');

function FunFacts({ show }) {
  const { data: funFacts, isFetching: factsFetching, isError: factsError } = useFunFactsQuery(
    show ? { name: show.title, year: show.year, kind: show.kind } : skipToken
  );

  const creditsRef = useRef(null);

  useEffect(() => {
    if (creditsRef.current) {
      const contentHeight = creditsRef.current.scrollHeight;
      const containerHeight = creditsRef.current.clientHeight;
      const duration = (contentHeight / containerHeight) * 30; // Adjust this multiplier as needed
      creditsRef.current.style.animationDuration = `${duration}s`;
    }
  }, [funFacts]);

  let content;
  if (!show || !show.title || !show.year) {
    content = <div>Error: Show details are missing</div>;
  } else if (factsFetching) {
    content = (
      <div className='text-center mt-4'>
        <l-helix size="150" speed="2.5" color="white"></l-helix>
      </div>
    );
  } else if (factsError) {
    content = <div className='text-center mt-4'>Error loading fun facts ☹️</div>;
  } else if (funFacts) {
    const allFacts = funFacts.facts.map((fact, index) => (
      <li key={index}>{fact}</li>
    ));

    content = (
      <div className="credits mt-4" ref={creditsRef}>
        {allFacts}

      </div>
    );
  }

  return (
    <Container>
      <div className="fun-facts-container">
        {content}
      </div>
      <span> powered by Gemini Ai <img src="https://upload.wikimedia.org/wikipedia/commons/f/f0/Google_Bard_logo.svg" alt="gemini-AI"  style={{ width: '15px', height: '15px', objectFit: 'cover' }} /></span>

    </Container>
  );
}

export default FunFacts;
