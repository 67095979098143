import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';



export const tvmazeApi = createApi({
  reducerPath: 'tvmazeApi',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://api.tvmaze.com',

  }),
  endpoints: (builder) => ({
    tvMazeShows: builder.query({
      // Define the query with 'lazy' to make it a lazy query
      query: () => `/shows`,
      // Define it as a lazy endpoint
      providesTags: ['TvMazeShows'],
    }),
  }),
});

// Export the lazy hook
export const { useTvMazeShowsQuery } = tvmazeApi;
